<template>
  <b-container>

    <b-row class="d-flex mb-2">
      <!-- Filters -->
      <b-col sm="6" xl="2">
        <b-form-group label="Account UUID">
          <b-form-input v-model="filters.account_uuid" type="search"/>
        </b-form-group>
      </b-col>

      <b-col sm="6" xl="2">
        <b-form-group label="Email">
          <b-form-input v-model="filters.email" type="search"/>
        </b-form-group>
      </b-col>

      <b-col sm="3" xl="1" align-self="center" class="mt-3">
        <b-button
          variant="primary"
          :disabled="loadingMembers"
          @click="requestPage">
          <span>Apply</span>
        </b-button>
      </b-col>

      <!-- Transfer Action -->
      <b-col sm="6" xl="2" offset-sm="0" offset-xl="4" align-self="center">
        <b-form-select
          :disabled="loadingCareCoordinators"
          id="care-coordinator-select"
          v-model="newCareCoordinator"
          class="form-control"
          required>
          <b-form-select-option :key="null" :value="null">
            Care Coordinator
          </b-form-select-option>
          <b-form-select-option
            v-for="careCoordinator in careCoordinators"
              :key="careCoordinator.userID"
              :value="careCoordinator.userID">
            {{ careCoordinator.firstName }} {{ careCoordinator.lastName }} ({{ careCoordinator.userID }})
          </b-form-select-option>
        </b-form-select>
      </b-col>

      <b-col sm="3" xl="1" align-self="center">
        <b-button
          :disabled="loadingCareCoordinators"
          variant="primary"
          @click="transfer">
          <span>Transfer</span>
        </b-button>
      </b-col>
    </b-row>

    <!-- Table -->
    <b-table
      :fields="fields"
      :items="items"
      head-variant="light" hover responsive small sticky-header="600px" striped>
      <template #cell(transfer)="member">
        <b-form-checkbox
          :id="'care-coordinator-member-transfer-' + member.item.account_uuid"
          v-model="pendingTransfers[member.item.account_uuid]"
          :name="'care-coordinator-member-transfer-' + member.item.account_uuid">
        </b-form-checkbox>
      </template>
    </b-table>

    <!-- Pagination -->
    <div class="row pt-2">
      <div class="col-12 d-flex justify-content-center">
        <b-pagination
          id="care-coordinator-members-pagination"
          :disabled="loadingMembers"
          @change="changePage"
          v-model="page"
          :total-rows="total"
          :per-page="itemsPerPage"
          variant="primary">
        </b-pagination>
      </div>
    </div>

  </b-container>
</template>

<script>

import axios from 'axios';
import moment from 'moment';

export default {
  name: 'CareCoordinatorMembers',
  components: {},
  props: {
    userID: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      newCareCoordinator: null,
      careCoordinators: [],
      loadingMembers: false,
      loadingCareCoordinators: false,
      pendingTransfers: {},
      page: 1,
      total: 0,
      itemsPerPage: 15,
      filters: {
        account_uuid: undefined,
        email: undefined,
      },
      items: [],
      fields: [
        {
          key: 'account_uuid',
          label: 'Account UUID',
        },
        {
          key: 'first_name',
          label: 'First Name',
        },
        {
          key: 'last_name',
          label: 'Last Name',
        },
        {
          key: 'email',
          label: 'Email',
        },
        {
          key: 'updated_at',
          label: 'Updated Time',
        },
        {
          key: 'transfer',
          label: 'Transfer',
        },
      ],
    };
  },
  async beforeMount() {
    await Promise.all([
      this.requestPage(),
      this.requestCareCoordinators(),
    ]);
  },
  methods: {
    changePage(page) {
      this.page = page;
      this.requestPage();
    },
    async transfer() {
      if (!this.newCareCoordinator) {
        this.$noty.error('Please select a Care Coordinator to be used in the transfer');
        return;
      }

      const accountUUIDs = [];
      Object.keys(this.pendingTransfers).forEach(accountUUID => {
        if (this.pendingTransfers[accountUUID]) {
          accountUUIDs.push(accountUUID);
        }
      });

      if (accountUUIDs.length === 0) {
        this.$noty.error('Please select at least one member to transfer');
        return;
      }

      try {
        await axios.post(`v1/care-coordinators/${this.userID}/transfer`, {
          new_cc_id: this.newCareCoordinator,
          account_uuids: accountUUIDs,
        });
        this.$noty.success('Members transfer requested with success');
      } catch (e) {
        this.$noty.error('Failed to transfer care coordinator members: ', e);
      } finally {
        await this.requestPage();
      }
    },
    async requestPage() {
      try {
        this.loadingMembers = true;

        const params = {
          offset: (this.page - 1) * this.itemsPerPage,
          limit: this.itemsPerPage,
        };
        if (this.filters.account_uuid) {
          params.account_uuid = this.filters.account_uuid;
        }
        if (this.filters.email) {
          params.email = this.filters.email;
        }

        const list = await this.$store.dispatch(
          'Core/fetchCareCoordinatorMembers',
          {
            careCoordinatorID: this.userID,
            payload: {
              params,
            },
          },
        );
        this.total = list.total;
        this.items = list.accounts.map(member => ({
          account_uuid: member.account_uuid,
          first_name: member.first_name,
          last_name: member.last_name,
          email: member.email,
          updated_at: moment.utc(member.updated_at.seconds * 1000).format('YYYY-MM-DD HH:mm:ss'),
          transfer: this.pendingTransfers[member.account_uuid],
        }));
      } catch (e) {
        this.$noty.error('Failed to fetch care coordinator members: ', e);
      } finally {
        this.loadingMembers = false;
      }
    },
    async requestCareCoordinators() {
      this.loadingCareCoordinators = true;
      try {
        const careCoordinators = await this.$store.dispatch('User/fetchCareCoordinators');
        this.careCoordinators = careCoordinators.map(careCoordinator => ({
          userID: careCoordinator.user_id,
          firstName: careCoordinator.user.firstname,
          lastName: careCoordinator.user.lastname,
        }))
          .filter(careCoordinator => careCoordinator.userID !== this.userID)
          .sort((a, b) => a.firstName.toUpperCase().localeCompare(b.firstName.toUpperCase()));
      } catch (e) {
        this.$noty.error('Failed to fetch care coordinators: ', e);
      } finally {
        this.loadingCareCoordinators = false;
      }
    },
  },
};
</script>

<style>
</style>
