<template>
  <b-container>
    <b-row>
      <b-col class="d-flex justify-content-end">
        <b-button size="sm" variant="secondary"
                  :disabled="!availableStates.length"
                  @click="updatingLicense.isNew = true"
                  v-b-modal.modal-edit-license>
          <span class="mr-2">Add state license</span>
          <feather type="plus"></feather>
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col>
        <b-table responsive=""
                 :fields="tableFields"
                 :items="activeLicenses"
                 show-empty
                 empty-text="No regulatory states added, add one using the button above">
          <template #cell(state)="data">
            {{ getStateText(data.value) }}
          </template>
          <template #cell(status)="data">
            <div class="d-flex w-100 justify-content-center">
              <feather v-if="data.value" class="text-success" type="check"></feather>
              <feather v-else class="text-danger" type="x"></feather>
            </div>
          </template>
          <template #cell(actions)="data">
            <div class="d-flex">
              <b-button v-b-tooltip.hover
                        title="Edit"
                        size="sm" variant="light"
                        class="mr-2"
                        @click="openEditLicense(data.item)"
                        v-b-modal.modal-edit-license>
                <feather type="edit"></feather>
              </b-button>
              <b-button v-b-tooltip.hover
                        title="Delete this license"
                        size="sm"
                        variant="light"
                        class="client-entry-action"
                        @click="deleteLicense(data.index)">
                <feather class="text-danger" type="trash"></feather>
              </b-button>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-modal id="modal-edit-license"
             :ok-title="!updatingLicense.isNew ? 'Confirm' : 'Add'"
             @ok="updateLicense"
             :ok-disabled="!isUpdatingLicenseValid"
             @cancel="resetUpdatingLicense"
             no-close-on-esc
             no-close-on-backdrop
             hide-header-close
             size="lg"
             :title="!updatingLicense.isNew ?
             `Updating license for state ${getStateText(updatingLicense.state)}` : 'Add new license'">
      <b-row>
        <b-col cols="6">
          <b-form-group label="State"
                        :disabled="!updatingLicense.isNew"
                        :state="updatingLicenseValidations().state"
                        invalid-feedback="State is required">
            <v-select id="state-selector"
                      :options="availableStates"
                      :disabled="!updatingLicense.isNew"
                      :searchable="true"
                      v-model="updatingLicenseState"/>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Regulatory status">
            <b-form-checkbox v-model="updatingLicense.status" name="check-button" switch></b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col cols="6">
          <b-form-group label="License ID"
                        :state="updatingLicenseValidations().license_id"
                        invalid-feedback="License ID is required">
            <b-form-input v-model="updatingLicense.license_id" required></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Expiration Date"
                        :state="updatingLicenseValidations().expiration_date"
                        invalid-feedback="You need to select a valid date">
            <VueCtkDateTimePicker format="YYYY-MM-DD"
                                  :min-date="minDateAvailable"
                                  label="Click to choose the expiration date"
                                  hint=""
                                  formatted="ll"
                                  right
                                  :no-button-now="true"
                                  :only-date="true"
                                  v-model="updatingLicense.expiration_date"/>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </b-container>
</template>

<script>
import moment from 'moment';
import VSelect from '@alfsnd/vue-bootstrap-select';
import { definitions } from '@sword-health/ui-core';

const defaultLicenseSpec = () => ({
  id: null,
  isNew: false,
  state: null,
  status: false,
  license_id: null,
  expiration_date: null,
});

export default {
  name: 'TherapistLicenseManagement',
  components: {
    VSelect,
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      updatingLicense: defaultLicenseSpec(),
      licenses: [],
      tableFields: [
        { key: 'state', label: 'State' },
        { key: 'status', label: 'Regulatory status' },
        { key: 'license_id', label: 'License ID' },
        { key: 'expiration_date', label: 'Expiration Date' },
        { key: 'actions', label: 'Actions' },
      ],
    };
  },
  beforeMount() {
    this.licenses = this.value;
  },
  computed: {
    states() {
      const states = definitions.getStatesByCountry(this.$store.getters['Core/getSelectedEnv']) || definitions.USAStatesOpts;
      return states.map(state => ({
        value: state.optValue,
        text: `${state.optValue} - ${state.optLabel}`,
      }));
    },
    activeLicenses() {
      return this.licenses.filter(l => !l.deleted).sort((a, b) => a.state.localeCompare(b.state));
    },
    isUpdatingLicenseValid() {
      return Object.entries(this.updatingLicenseValidations()).every(([ , valid ]) => valid);
    },
    minDateAvailable() {
      return moment().add(1, 'day').format('YYYY-MM-DD');
    },
    availableStates() {
      const licensesToCheck = this.activeLicenses
        .filter(license => this.updatingLicense.isNew || license.state !== this.updatingLicense.state);
      return this.states.filter(state => !licensesToCheck.find(license => license.state === state.value));
    },
    updatingLicenseState: {
      set(state) {
        this.updatingLicense.state = state.value;
      },
      get() {
        return this.states.find(s => s.value === this.updatingLicense.state);
      },
    },
  },
  methods: {
    getStateText(state) {
      if (!state) return '';
      return this.states.find(s => s.value === state).text;
    },
    async deleteLicense(index) {
      const result = await this.$bvModal.msgBoxConfirm(
        'Are you sure you want to remove this state? The regulatory status and license information will be removed as well.',
        {
          title: 'Confirm state license deletion',
          okTitle: 'Delete',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true,
        },
      );

      if (!result) {
        return;
      }

      if (!this.activeLicenses[index].id) {
        this.activeLicenses.splice(index, 1);
        return;
      }

      this.activeLicenses[index].deleted = true;
    },
    openEditLicense(license) {
      this.updatingLicense = license;
      this.updatingLicense.isNew = false;
    },
    updateLicense() {
      const licenseDataToStore = JSON.parse(JSON.stringify(this.updatingLicense));
      if (this.updatingLicense.isNew) {
        this.licenses.push(licenseDataToStore);
        this.resetUpdatingLicense();
        return;
      }

      const licenseIndex = this.activeLicenses.find(l => l.state === this.updatingLicense.state);
      this.activeLicenses.splice(licenseIndex, 1, this.updatingLicense);
      this.resetUpdatingLicense();
    },
    resetUpdatingLicense() {
      this.updatingLicense = defaultLicenseSpec();
    },
    updatingLicenseValidations() {
      return {
        state: (this.updatingLicense.state || '').trim().length > 0,
        license_id: (this.updatingLicense.license_id || '').trim().length > 0,
        expiration_date: moment(this.updatingLicense.expiration_date).isValid(),
      };
    },
  },
  watch: {
    licenses: {
      handler(newValue) {
        this.$emit('input', newValue);
      },
      deep: true,
    },
    value: {
      handler(newValue) {
        this.licenses = newValue;
      },
      deep: true,
    },
  },
};
</script>

<style>
#state-selector {
  height: calc(1.5em + 0.75rem + 2px);
}

#state-selector button.v-select-toggle {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  height: 100%;
}
</style>
