<template>
  <b-container class="mt-4" fluid>
    <b-row>
      <b-col cols="8">
        <h3>Editing {{ user.role }} {{ user.firstname }} {{ user.lastname }}</h3>
      </b-col>
      <b-col cols="4">
        <b-button variant="primary" :disabled="saving" @click="saveUser" class="float-right">
          <b-spinner v-if="saving" small></b-spinner>
          <span v-else>Save changes</span>
        </b-button>
      </b-col>
    </b-row>

    <b-overlay :show="loading">
      <template #overlay>
        <div class="text-center">
          <b-icon animation="spin" icon="arrow-clockwise" font-scale="3"></b-icon>
          <p id="cancel-label">loading</p>
        </div>
      </template>
      <b-tabs class="mt-3" content-class="mt-3">
        <b-tab active title="Personal information">
          <b-row>
            <b-col lg="4" cols="12" class="mb-4">
              <b-card>
                <b-row>
                  <b-col cols="6">
                    <b-form-group label="Username" label-for="username">
                      <b-form-input id="username" v-model="user.username" disabled/>
                    </b-form-group>
                    <b-form-group label="ACL UUID" label-for="acl_uuid">
                      <b-form-input id="acl_uuid" v-model="user.acl_uuid" disabled/>
                    </b-form-group>
                    <b-form-group label="Email" label-for="email">
                      <b-form-input id="email" v-model="user.email" :disabled="!$acl.check('users.edit')" type="email" />
                    </b-form-group>
                    <b-form-group label="First name" label-for="firstname">
                      <b-form-input id="firstname" v-model="user.firstname" :disabled="!$acl.check('users.edit')" />
                    </b-form-group>
                    <b-form-group label="Last name" label-for="lastname">
                      <b-form-input id="lastname" v-model="user.lastname" :disabled="!$acl.check('users.edit')" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group label="Phone" label-for="phone">
                      <b-form-input id="phone" v-model="user.telephone" :disabled="!$acl.check('users.edit')" />
                    </b-form-group>
                    <b-form-group label="Twilio Phone Number" label-for="externalPhoneNumber">
                      <b-form-input id="externalPhoneNumber" v-model="user.external_phone_number"
                                    :disabled="!$acl.check('users.edit')" type="tel" />
                    </b-form-group>
                    <InputFeedbackMessage v-if="!isTwilioPhoneNumberValid" class="text-danger"
                                          data-testid="userPhoneInputMessageSMSComplaint" icon-name="alert-triangle"
                                          message="This Phone Number is not eligible for SMS" />
                    <b-form-group label="Metric System" label-for="metricSystem">
                      <b-form-select id="metricSystem" v-model="user.measurement_system"
                                     :disabled="!$acl.check('users.edit')" required>
                        <b-form-select-option value="metric">Metric</b-form-select-option>
                        <b-form-select-option value="imperial">Imperial</b-form-select-option>
                      </b-form-select>
                    </b-form-group>
                    <b-form-group label="NPI" label-for="npi">
                      <b-form-input id="npi" v-model="user.npi" :disabled="!$acl.check('users.edit')" maxlength="10" />
                    </b-form-group>
                    <b-form-group label="Rippling ID" label-for="rippling_id">
                      <b-form-input id="rippling_id" v-model="therapistConfigs.rippling_id" :disabled="!$acl.check('users.edit')" maxlength="255" />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-form-group label="Conversational Languages">
                      <b-form-checkbox-group id="user-conversational-language-selection" v-model="user.languages" :options="languageOptions" />
                    </b-form-group>
                    <b-form-group v-if="!therapistConfigs.is_lead" label="Assigned PT Lead" label-class="font-weight-bold" label-for="pt-lead">
                      <b-form-select id="pt-lead" v-model="user.lead_id" class="form-control" required>
                        <b-form-select-option :value="null">No Lead PT assigned</b-form-select-option>
                        <b-form-select-option v-for="professional in leadProfessionals" :key="professional.userRefID" :value="professional.userRefID">
                          {{ professional.firstname }} {{ professional.lastname }} ({{ professional.userRefID }})
                        </b-form-select-option>
                      </b-form-select>
                    </b-form-group>
                    <b-form-group :state="isPresentationVideoURLValid()" invalid-feedback="Invalid URL provided"
                                  label="Presentation Video" label-for="presentation_video">
                      <b-form-input id="presentation_video" v-model="user.presentation_video" maxlength="255"/>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>

            <b-col lg="4" cols="12" class="mb-4">
              <b-card>
                <b-form-group v-slot="{ ariaDescribedby }" label="Data level access">
                  <b-form-radio-group id="radio-group-data-level-access" v-model="user.data_level_access" :disabled="true"
                                      :aria-describedby="ariaDescribedby" :options="dataLevelAccessOptions"
                                      name="radio-group-data-level-access" stacked>
                  </b-form-radio-group>
                </b-form-group>
                <b-form-group v-if="showPtAvailableCountries" label="Country" label-class="font-weight-bold" label-for="country">
                  <b-form-select id="country" v-model="therapistConfigs.country" class="form-control" required>
                    <b-form-select-option v-for="country in ptAvailableCountries" :value="country" :key="country">
                      {{ country }}
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>
                <b-form-group v-slot="{ ariaDescribedby }" label="Unit:">
                  <b-form-radio-group id="checkbox-group-unit" v-model="user.unit" :disabled="true" :options="unitOptions"
                                      :aria-describedby="ariaDescribedby" name="checkbox-group-unit" stacked/>
                </b-form-group>

                <template v-if="isCareCoordinator(user)">
                  <b-form-group class="mb-0" label="Care Coordinator" label-class="font-weight-bold pt-0" label-size="lg">
                    <b-form-group label="Unit Tags" label-class="font-weight-bold" label-for="unit-tags">
                      <b-form-checkbox-group id="unit-tags" v-model="user.units" :options="unitTagsOptions" switches />
                    </b-form-group>

                    <b-form-group label="Assignment Type" label-class="font-weight-bold" label-for="radio-group-assignment-type">
                      <b-form-radio-group id="radio-group-assignment-type" v-model="user.assignment_type"
                                          :options="assignmentTypeOptions" name="radio-group-assignment-type" stacked />
                    </b-form-group>
                  </b-form-group>

                  <b-form-group label="Transfer Members" label-class="font-weight-bold" label-for="transfer-cc-members">
                    <b-button class="ml-3" @click="transferMembers" type="button" :disabled="isTransferringMembers" variant="primary">
                      <b-spinner label="Requesting transfer..." variant="light" small v-if="isTransferringMembers"/>
                      <span v-else>Transfer</span>
                    </b-button>
                  </b-form-group>
                </template>

                <template v-else>
                  <b-form-group v-slot="{ ariaDescribedby }" label="Program Type">
                    <b-form-radio-group id="radio-group-program-type" v-model="user.program_type" :aria-describedby="{ ariaDescribedby }"
                                        :options="programTypeOptions" name="radio-group-program-type" stacked />
                  </b-form-group>
                  <b-form-group v-if="isProgramTypeVirtualPT(user)" v-slot="{ ariaDescribedby }" label="Provider Strategy">
                    <b-form-checkbox id="has_provider_enabled" v-model="user.has_provider_enabled"
                                     name="has_provider_enabled" switch>Has Provider Enabled</b-form-checkbox>
                  </b-form-group>
                </template>
                <b-form-group v-slot="{ ariaDescribedby }" label="Professional type">
                  <b-form-checkbox v-model="user.treatment_flow">Is Treatment Flow</b-form-checkbox>
                  <b-form-checkbox v-model="user.intake_flow">Is Intake Flow</b-form-checkbox>
                </b-form-group>
                <b-form-group :state="isMoveConfigurationValid()" v-if="user.unit === 'move'"
                              invalid-feedback="Invalid json. Unable to parse." label="Move configurations">
                  <b-form-textarea v-model="user.move_configuration" rows="3" size="lg" type="v-textarea"/>
                </b-form-group>
              </b-card>
            </b-col>

            <b-col lg="4" cols="12" class="mb-4">
              <b-row>
                <b-col cols="12">
                  <b-card>
                    <b-form-group label="Professional tags" description="note: used for automatic selection on enrollment">
                      <b-form-checkbox v-model="is_regular" name="pt_tags" stacked required>
                        Regular <span class="badge badge-info">client match</span></b-form-checkbox>
                      <b-form-checkbox v-model="is_demo" name="pt_tags" stacked required>
                        Demo <span class="badge badge-info">client match</span></b-form-checkbox>
                      <b-form-checkbox v-model="is_test" name="pt_tags" stacked required>
                        Test <span class="badge badge-info">client match</span></b-form-checkbox>
                      <b-form-checkbox v-model="is_contractor" name="pt_tags" stacked required>
                        Contractor <span class="badge badge-info">client match</span></b-form-checkbox>
                      <b-form-checkbox v-model="is_available_for_predict" name="pt_tags" stacked required>
                        High risk (predict)</b-form-checkbox>
                      <b-form-checkbox v-model="is_lead" name="pt_tags" stacked required>
                        Lead </b-form-checkbox>
                      <b-form-checkbox v-model="is_ai" name="pt_tags" stacked required>
                        AI </b-form-checkbox>
                      <b-form-checkbox v-model="is_gutenberg" name="pt_tags" stacked required>
                        Gutenberg </b-form-checkbox>
                    </b-form-group>

                    <ExtraRoles v-if="$acl.check('update.user.roles') && !isProfessional(user)" v-model="user.extra_roles"/>
                  </b-card>
                </b-col>
              </b-row>

              <b-row class="mt-3">
                <b-col cols="12">
                  <CapacityManagement :therapist-configs="therapistConfigs"></CapacityManagement>
                </b-col>
              </b-row>
              <b-row class='mt-3'>
                <b-col cols='12'>
                  <b-card header="UML - Scoring ALgorithm Version">
                    <b-form-select id="uml_scoring_algorithm_version"
                                   v-model="therapistConfigs.scoring_algorithm_id"
                                   name="uml_scoring_algorithm_version"
                                   class="form-control">
                      <b-form-select-option
                        v-for="version in umlScoringAlgorithVersions"
                        :key="version.id"
                        :value="version.id">
                        {{ version.config_slug }}
                      </b-form-select-option>
                    </b-form-select>
                  </b-card>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab title="Institutions">
          <b-table :fields="institutionsTable.fields" :items="institutionsTable.items" head-variant="light" hover
                   responsive small
                   sticky-header="600px" striped>
            <template #head(available_for_onboarding)="scope">
              Available For Onboarding
              <small>
                (
                <feather type="check-square" @click="toggleAllAvailableForOnboarding(true)"/>
                /
                <feather type="square" @click="toggleAllAvailableForOnboarding(false)"/>
                )
              </small>
            </template>
            <template #head(ecosystems)="scope">
              Institution Ecosystems Units
              <b-icon-info-circle id="institution_ecosystems_units_id"></b-icon-info-circle>
              <b-tooltip target="institution_ecosystems_units_id" triggers="hover"> PT will only be added to the unit it
                belongs to
              </b-tooltip>
            </template>

            <template #cell(is_test)="institution">{{ institution.item.is_test | boolValue }}</template>
            <template #cell(associated_with_user)="institution">
              <b-form-checkbox :id="'user-institution-' + institution.item.institutionID"
                               v-model="userInstitutionsMap[institution.item.institutionID].enabled"
                               :disabled="isAssociatedWithUserDisabled(institution.item)"
                               :name="'user-institution-' + institution.item.institutionID">
              </b-form-checkbox>
            </template>
            <template #cell(available_for_onboarding)="institution">
              <b-form-checkbox v-if="'Professional' === user.role"
                               :id="'user-institution-onboarding-' + institution.item.institutionID"
                               v-model="userInstitutionsMap[institution.item.institutionID].available_for_onboarding"
                               :disabled="isOnboardingDisabled(institution.item)"
                               :name="'user-institution-onboarding-' + institution.item.institutionID">
                <span :hidden="hideTooltipMessage(institution.item)"
                      :target="'user-institution-onboarding-' + institution.item.institutionID" class="text-secondary"
                      style="font-size: small">
                  {{ tooltipMessage(institution.item) }}
                </span>
              </b-form-checkbox>
            </template>
          </b-table>
        </b-tab>
        <b-tab v-if="$acl.check('therapist.licenses.edit') && isNotMoveUnit" title="License Management">
          <TherapistLicenseManagement v-model="licenses"></TherapistLicenseManagement>
        </b-tab>
        <b-tab v-if="$acl.check('therapist.licenses.edit') && isNotMoveUnit" title="Credential Management">
          <CredentialGroupManagement
            :allCredentials="this.credentialGroupsData.allCredentials"
            :professionalCredentials="this.credentialGroupsData.professionalCredentials.currentState">
          </CredentialGroupManagement>
        </b-tab>
        <b-tab v-if="isCareCoordinator(user)" title="CC Members">
          <CareCoordinatorMembers :userID="user.id"></CareCoordinatorMembers>
        </b-tab>
      </b-tabs>
    </b-overlay>
  </b-container>
</template>

<script>
import axios from 'axios';
import { omitBy } from 'lodash';
import * as R from 'ramda';

import { professionalsLanguages } from '@/scripts/definitions';
import utils from '@/scripts/tools/utils';
import userMixin from '@/mixins/user';
import TherapistLicenseManagement from '@/views/Users/Professionals/EditDeprecated/partial/TherapistLicenseManagement.vue';
import CredentialGroupManagement from '@/views/Users/Professionals/EditDeprecated/partial/CredentialGroupManagement.vue';
import CareCoordinatorMembers from '@/views/Users/Professionals/EditDeprecated/partial/CareCoordinatorMembers.vue';
import ExtraRoles from '@/views/Users/partial/ExtraRoles.vue';
import InputFeedbackMessage from '@/components/InputFeedbackMessage.vue';
import {
  UNIT_BLOOM, UNIT_MOVE, UNIT_SWORD, UNIT_MIND,
} from '@/scripts/constants';
import { isNullOrEmpty } from '@/helpers';
import CapacityManagement from '@/views/Users/Professionals/EditDeprecated/partial/CapacityManagement.vue';

export default {
  name: 'ProfessionalEditDeprecated',
  components: {
    InputFeedbackMessage,
    TherapistLicenseManagement,
    CredentialGroupManagement,
    ExtraRoles,
    CapacityManagement,
    CareCoordinatorMembers,
  },
  mixins: [ userMixin ],
  data() {
    return {
      saving: false,
      loading: false,
      languageOptions: professionalsLanguages.map(language => ({
        value: language.value,
        text: language.text,
      })),
      dataLevelAccessOptions: [
        { value: 'normal', html: '<b>Normal</b>: User will not be allowed to see data from strict US clients' },
        { value: 'strict', html: '<b>Strict - Zero Tolerance</b>: User will be allowed to see data from all clients including strict US' },
      ],
      unitOptions: [
        { value: UNIT_SWORD, html: 'DPT' },
        { value: UNIT_BLOOM, text: 'Bloom' },
        { value: UNIT_MOVE, text: 'Move' },
        { value: UNIT_MIND, text: 'Mind' },
      ],
      unitTagsOptions: [
        { text: 'DPT', value: UNIT_SWORD },
        { text: 'Bloom', value: UNIT_BLOOM },
        { text: 'Move', value: UNIT_MOVE },
        { text: 'Mind', value: UNIT_MIND },
      ],
      assignmentTypeOptions: [
        // eslint-disable-next-line max-len
        { value: 'normal', html: '<b>Normal</b>: Idlest one will be assigned new members when their backlog is at most double the size of the idlest "Half" CC\'s backlog' },
        // eslint-disable-next-line max-len
        { value: 'half', html: '<b>Half</b>: Idlest one will be assigned new members when their backlog is less than half the size of the idlest "Normal" CC\'s backlog' },
        { value: 'none', html: '<b>None</b>: Not considered for assignment' },
      ],
      programTypeOptions: [
        { value: 'VIRTUAL_PT', html: '<b>Virtual PT</b>: PT for physical therapy' },
        { value: 'PREVENTIVE', html: '<b>Preventive</b>: User for preventive members' },
      ],
      user: {
        id: null,
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        external_phone_number: null,
        username: '',
        clinical_assistants: [],
        extra_roles: [],
        intake_flow: false,
        treatment_flow: true,
        lead_id: '',
        move_configuration: null,
        presentation_video: null,
        unit: null,
      },
      institutions: [],
      userInstitutionsMap: {},
      clinicalAssistants: [],
      professionals: [],
      leadProfessionals: [],
      originalData: {},
      valuesLoadedString: '',
      licenses: [],
      credentialGroupsData: {
        allCredentials: [],
        professionalCredentials: {
          currentState: [],
          originalState: [],
        },
      },
      therapistConfigs: {
        is_clinical_assistant_manager: false,
        is_ai: false,
        is_gutenberg: false,
        is_lead: false,
        is_test: false,
        is_available_for_predict: false,
        is_contractor: false,
        is_demo: false,
        is_regular: false,
        lead_id: '',
        tags: [],
        intake_flow: false,
        treatment_flow: true,
        country: '',
        rippling_id: null,
      },
      isTwilioPhoneNumberValid: true,
      umlScoringAlgorithVersions: [],
      isTransferringMembers: false,
      ptAvailableCountries: [],
      showPtAvailableCountries: false,
    };
  },
  computed: {
    institutionsTable() {
      const fields = [
        {
          key: 'institutionID',
          label: 'ID',
        },
        {
          key: 'name',
          label: 'Name',
        },
        {
          key: 'unique_key',
          label: 'Client Reference (Unique Key)',
        },
        {
          key: 'ecosystems',
          label: 'Institution Ecosystems Units',
        },
        {
          key: 'is_test',
          label: 'Is Test',
        },
        {
          key: 'associated_with_user',
          label: 'Associated with user',
        },
        {
          key: 'available_for_onboarding',
          label: 'Available for onboarding',
        },
      ];

      const items = this.institutions.map(i => ({
        institutionID: i.institutionID,
        name: i.name,
        unique_key: i.unique_key,
        ecosystems: i.ecosystems.map(e => e.unit)
          .join(', '),
        is_test: i.is_test,
        associated_with_user: null,
        available_for_onboarding: null,
        credential_group_id: i.config ? i.config.credential_group_id : null,
      }));

      return {
        fields,
        items,
      };
    },
    dirty() {
      return this.valuesLoadedString !== this.getDataAsString();
    },
    // to be deprecated ( remove tags )
    is_contractor: {
      get() {
        return Boolean(this.therapistConfigs.is_contractor) || this.therapistConfigs.tags.includes('contractor') || false;
      },
      set(value) {
        this.therapistConfigs.is_contractor = value;
        if (value && !this.therapistConfigs.tags.includes('contractor')) {
          this.therapistConfigs.tags.push('contractor');
        } else {
          this.therapistConfigs.tags = this.therapistConfigs.tags.filter(tag => tag !== 'contractor');
        }
      },
    },
    is_ai: {
      get() {
        return Boolean(this.therapistConfigs.is_ai) || false;
      },
      set(value) {
        this.therapistConfigs.is_ai = value;
      },
    },
    is_gutenberg: {
      get() {
        return Boolean(this.therapistConfigs.is_gutenberg) || false;
      },
      set(value) {
        this.therapistConfigs.is_gutenberg = value;
      },
    },
    is_lead: {
      get() {
        return Boolean(this.therapistConfigs.is_lead) || false;
      },
      set(value) {
        this.therapistConfigs.is_lead = value;
      },
    },
    is_test: {
      get() {
        return Boolean(this.therapistConfigs.is_test) || false;
      },
      set(value) {
        this.therapistConfigs.is_test = value;
      },
    },
    is_available_for_predict: {
      get() {
        return Boolean(this.therapistConfigs.is_available_for_predict) || false;
      },
      set(value) {
        this.therapistConfigs.is_available_for_predict = value;
      },
    },
    is_demo: {
      get() {
        return Boolean(this.therapistConfigs.is_demo) || false;
      },
      set(value) {
        this.therapistConfigs.is_demo = value;
      },
    },
    is_regular: {
      get() {
        return Boolean(this.therapistConfigs.is_regular) || false;
      },
      set(value) {
        this.therapistConfigs.is_regular = value;
      },
    },
    isNotMoveUnit() {
      return this.user.unit !== 'move';
    },
  },
  async beforeMount() {
    this.loading = true;
    try {
      await this.requestUser();
      await this.requestTherapistCredentialGroups();
      await this.requestTherapistLicenses();
      await this.requestInstitutions();
      await this.updateUserInstitutionsMap();
      await this.requestLeadProfessionals();
      await this.requestUMLScoringAlgorithmVersions();
      await this.fetchPtAvailableCountries();
      this.valuesLoadedString = this.getDataAsString();
      this.loading = false;
    } catch (e) {
      console.error(e);
      this.$noty.error('Unable to fetch user information');
    }
  },
  methods: {
    toggleAllAvailableForOnboarding(status) {
      this.institutions.forEach(institution => {
        const institutionMap = this.userInstitutionsMap[institution.institutionID];
        if (!institutionMap.enabled) {
          return;
        }
        institutionMap.available_for_onboarding = status;
      });
    },
    getDataAsString() {
      const sortedCredentialGroups = this
        .credentialGroupsData
        .professionalCredentials
        .currentState
        .sort((a, b) => a.id - b.id);

      return JSON.stringify({
        user: this.user,
        userInstitutionsMap: this.userInstitutionsMap,
        licenses: this.licenses,
        credentialGroups: sortedCredentialGroups,
      });
    },
    async requestUser() {
      const { data } = await axios.get(`v1/users/${this.$route.params.id}`, { params: { client: 'back-office' } });
      data.user.roles = data.user.roles || [];
      data.user.extra_roles = [ ...new Set(data.user.roles.map(role => role.name)) ];
      data.user.languages = (data.user.languages || []).map(language => language.code);
      this.user = Object.assign({}, this.user, data.user);
      this.user.has_provider_enabled = !!this.user.has_provider_enabled;
      this.professionals = data.professionals;

      if (data.therapistConfigs && data.therapistConfigs.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        this.therapistConfigs = data.therapistConfigs[0];
      }

      const {
        treatment_flow: treatmentFlow,
        intake_flow: intakeFlow,
        npi,
        lead_id: leadId,
      } = this.therapistConfigs;

      this.user.treatment_flow = !!treatmentFlow;
      this.user.intake_flow = !!intakeFlow;
      this.user.npi = npi;
      this.user.lead_id = leadId;

      this.user.move_configuration = this.therapistConfigs.move_configuration;
      this.user.presentation_video = null;
      if (data && data.therapistInformation && data.therapistInformation.length > 0) {
        this.user.presentation_video = data.therapistInformation[0].presentation_video;
      }

      if (data.careCoordinator) {
        const {
          assignment_type: assignmentType,
          units,
        } = data.careCoordinator;

        this.user.assignment_type = assignmentType;
        this.user.units = units.map(({ unit }) => unit);
      }

      this.userInstitutions = data.institutions;
      this.originalData = JSON.parse(JSON.stringify({
        user: data.user,
      }));
    },
    async requestLeadProfessionals() {
      try {
        const { data } = await axios.get('v1/professionals/leads', { params: { client: 'back-office' } });
        const professionals = data.filter(professional => professional.userRefID.toString() !== this.$route.params.id);
        const leads = [];
        professionals.forEach(professional => {
          if (!leads.find(l => l.userRefID === professional.userRefID)) {
            leads.push(professional);
          }
        });
        this.leadProfessionals = leads;
      } catch (error) {
        throw new Error('Unable to fetch professional leads');
      }
    },
    async requestTherapistLicenses() {
      const { data } = await axios.get(`v1/therapists/${this.$route.params.id}/licenses`, { params: { client: 'back-office' } });
      if (!data?.licenses) {
        throw new Error('Unable to fetch therapist licenses');
      }
      this.licenses = data.licenses.map(license => {
        license.state = license.geographical_state;
        license.status = Boolean(license.regulatory_status);
        license.deleted = false;
        return license;
      });
    },
    async requestTherapistCredentialGroups() {
      const {
        allCredentials: refreshedAllCredentials,
        professionalCredentials,
      } = await this.getCredentialGroupsData(this.$route.params.id);

      const {
        allCredentials,
        professionalCredentials: {
          currentState,
          originalState,
        },
      } = this.credentialGroupsData;

      allCredentials.splice(0, allCredentials.length, ...refreshedAllCredentials);
      currentState.splice(0, currentState.length, ...professionalCredentials);
      originalState.splice(0, originalState.length, ...[ ...professionalCredentials ]);
    },
    async requestUMLScoringAlgorithmVersions() {
      const { data } = await axios.get('/v1/users/uml/scoring-algorithms', { params: { client: 'back-office' } });
      if (!data) {
        throw new Error('Unable to fetch UML scoring algorithm versions');
      }
      this.umlScoringAlgorithVersions = data;
    },
    async getCredentialGroupsData(therapistId) {
      const responses = await Promise.all([
        axios.get('v1/credential-groups'),
        axios.get(`v1/therapists/${therapistId}/credential-groups`),
      ]);

      if (responses.some(res => (res.status !== 200))) {
        throw new Error('Unable to fetch therapist\'s credential groups');
      }

      const [ allCredentials, professionalCredentials ] = responses;

      return {
        allCredentials: allCredentials.data,
        professionalCredentials: professionalCredentials.data,
      };
    },
    fetchPtAvailableCountries() {
      return axios.get('/v1/configs/pt_available_countries')
        .then(({ data }) => {
          if (data.value) {
            this.ptAvailableCountries = data.value.split(',');
            this.showPtAvailableCountries = true;
          }
        });
    },
    async saveCredentialGroupChanges() {
      const profCreds = this.credentialGroupsData.professionalCredentials;
      const therapistUserId = this.$route.params.id;

      const credentialsToAdd = profCreds.currentState.filter(credential => (
        !profCreds.originalState.some(originalCred => (
          credential.id === originalCred.id
        ))
      ));

      const credentialsToDel = profCreds.originalState.filter(credential => (
        !profCreds.currentState.some(currCredential => (
          currCredential.id === credential.id
        ))
      ));

      const addPromises = credentialsToAdd.map(({ id }) => (
        axios.post(`v1/therapists/${therapistUserId}/credential-groups`, {
          credentialGroupId: id,
        })
      ));

      const deletePromises = credentialsToDel.map(({ id }) => (
        axios.delete(`v1/therapists/${therapistUserId}/credential-groups/${id}`)
      ));

      await Promise.all([ ...addPromises, ...deletePromises ]);
      await this.requestTherapistCredentialGroups();
    },
    async saveTherapistLicenses() {
      const reqMapper = license => ({
        id: license.id,
        user_id: this.$route.params.id,
        license_id: license.license_id,
        geographical_state: license.state,
        regulatory_status: Number(license.status),
        expiration_date: license.expiration_date,
      });

      const licensesForRequest = this.licenses.filter(l => !l.deleted).map(reqMapper);
      const newLicenses = licensesForRequest.filter(l => !l.id);
      const licensesToUpdate = licensesForRequest.filter(l => l.id);
      const licensesToDelete = this.licenses.filter(l => l.deleted).map(reqMapper);

      const url = `v1/therapists/${this.$route.params.id}/licenses`;

      if (newLicenses.length) {
        await axios.post(url, newLicenses);
      }

      if (licensesToUpdate.length) {
        await axios.put(url, licensesToUpdate);
      }

      await Promise.all(
        licensesToDelete.map(license => axios.delete(`${url}/${license.id}`)),
      );

      await this.requestTherapistLicenses();
    },
    async requestInstitutions() {
      try {
        const isTestTherapist = this.isTestTherapist();
        const availableInstitutions = await this.$store.dispatch('Core/fetchEnvInstitutions', {
          client: 'back-office',
          params: {
            is_test_therapist: isTestTherapist,
          },
        });
        this.userInstitutionsMap = availableInstitutions.reduce((carry, item) => {
          carry[item.institutionID] = {
            enabled: false,
            available_for_onboarding: false,
          };
          return carry;
        }, {});
        this.institutions = availableInstitutions;
      } catch (e) {
        this.$noty.error('Failed to fetch institutions: ', e);
      }
    },
    updateUserInstitutionsMap() {
      /* eslint-disable */
      for (const institution of this.institutions) {
        const userInstitution = this.userInstitutions.find(i => i.institutionID === institution.institutionID);

        if (!userInstitution) {
          // check if institution.institutionID exists in this.userInstitutionsMap
          if (!this.userInstitutionsMap[institution.institutionID]) {
            this.userInstitutionsMap[institution.institutionID] = {
              enabled: false,
              available_for_onboarding: false,
            };

            continue;
          }

          this.userInstitutionsMap[institution.institutionID].enabled = false;
          this.userInstitutionsMap[institution.institutionID].available_for_onboarding = false;

          continue;
        }

        this.userInstitutionsMap[institution.institutionID].enabled = true;
        if (this.user.role !== 'Professional') {
          continue;
        }

        if (this.isTestTherapist() && !institution.is_test) {
          this.userInstitutionsMap[institution.institutionID].available_for_onboarding = false;
          continue;
        }

        const professional = this.professionals.find(p => p.institutionRefID === userInstitution.institutionID);
        if (!professional) {
          this.userInstitutionsMap[institution.institutionID].available_for_onboarding = false;
          continue;
        }

        let { available_for_onboarding: availableForOnboarding } = professional;
        availableForOnboarding = availableForOnboarding !== null ? availableForOnboarding : true;

        this.userInstitutionsMap[institution.institutionID].available_for_onboarding = Boolean(availableForOnboarding);
      }
      /* eslint-enable */
    },
    async saveUser() {
      if (this.user.role === 'Professional' && !this.isValidNPI(this.user.npi)) {
        this.$noty.error('National Provider Identifier (NPI) must be a 10-digit number');
        return;
      }

      if (this.user.role === 'Professional' && !this.isMoveConfigurationValid()) {
        this.$noty.error('Move configuration must be a valid json');
        return;
      }

      this.saving = true;
      this.isTwilioPhoneNumberValid = true;

      if (this.therapistConfigs.is_lead > 0) {
        this.user.lead_id = null;
      }

      this.user = utils.convertUserHasProviderEnabled(this.user);
      const userFieldsToUpdate = omitBy(this.user, (value, key) => this.originalData.user[key] === value);
      let requestData = Object.assign({}, userFieldsToUpdate, { institutions: this.userInstitutionsMap });
      requestData = Object.assign({}, requestData, { therapist_configs: this.therapistConfigs });

      // to be deprecated ( remove tags )
      requestData.tags = requestData.therapist_configs?.tags || [];

      try {
        await this.saveTherapistLicenses();

        axios.put(`v1/users/${this.$route.params.id}`, requestData)
          .then(async response => {
            if (response?.data && response.data.error && response.data.message && !response.data.updated) {
              this.$noty.error(`Unable to update user: ${response.data.message}`);
              return;
            }

            await this.saveCredentialGroupChanges();
            this.valuesLoadedString = this.getDataAsString();
            this.$noty.success('Therapist data updated successfully!');
          })
          .catch(error => {
            if (R.has('external_phone_number', error.response?.data?.error_description)) {
              this.isTwilioPhoneNumberValid = false;
            }

            if (error?.response?.status !== 403) {
              const errors = Object.values(error.response.data.error_description);
              if (errors.length >= 1) {
                errors.forEach(err => {
                  this.$noty.error(err);
                });
              } else {
                this.$noty.error(`Unable to update user: ${JSON.stringify(error.response.data.error_description)}`);
              }
            }
          });
      } catch (err) {
        this.$noty.error(`Unable to update therapist: ${JSON.stringify(err.response.data.error_description)}`);
      } finally {
        this.saving = false;
      }
    },
    isValidNPI(npi) {
      if (!npi) {
        return true; // allow empty
      }
      return npi.match(/^\d{10}$/);
    },
    ptMissingCredential(institution, unit) {
      const professionalCredentialIDs = this.credentialGroupsData.professionalCredentials.currentState.map(({ id }) => id);
      const userInstitution = this.institutions.find(i => i.institutionID === institution.institutionID);
      const unitCredentialId = userInstitution.ecosystems?.find(e => e.unit === unit)?.credential_group_id;
      if (!unitCredentialId) {
        return false;
      }

      return !professionalCredentialIDs.includes(unitCredentialId);
    },
    tooltipMessage(institution) {
      if (institution.credential_group_id !== null) {
        const credential = this.credentialGroupsData.allCredentials.find(c => c.id === institution.credential_group_id);
        return credential?.name ? `Requires ${credential.name}` : null;
      }
      return null;
    },
    isOnboardingDisabled(institution) {
      if (!institution.ecosystems.includes(this.user.unit)) {
        return true;
      }
      let onboardingDisabled = !this.$acl.check('users.edit') || !this.userInstitutionsMap[institution.institutionID].enabled;

      // The value of (this.user.treatment_flow) changes every time the config 'Is Treatment Flow' is checked or unchecked
      // This way it updates which logic to use for Institutions Tab every time user checks or unchecks config
      if (this.user.treatment_flow && !onboardingDisabled) {
        onboardingDisabled = this.ptMissingCredential(institution, this.user.unit);
      }

      if (this.isTestTherapist() && !institution.is_test) {
        onboardingDisabled = true;
      }
      if (onboardingDisabled) {
        this.userInstitutionsMap[institution.institutionID].available_for_onboarding = false;
      }

      return onboardingDisabled;
    },
    isAssociatedWithUserDisabled(institution) {
      if (!this.user.unit) {
        return !this.$acl.check('users.edit');
      }
      if (!institution.ecosystems.includes(this.user.unit)) {
        return true;
      }
      return !this.$acl.check('users.edit');
    },
    hideTooltipMessage(institution) {
      return !this.userInstitutionsMap[institution.institutionID].enabled
        || (this.userInstitutionsMap[institution.institutionID].enabled && !this.isOnboardingDisabled(institution));
    },
    isMoveConfigurationValid() {
      try {
        JSON.parse(this.user?.move_configuration || '{}');
        return true;
      } catch (error) {
        return false;
      }
    },
    isPresentationVideoURLValid() {
      const urlRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(\/\S*)?$/;
      return isNullOrEmpty(this.user?.presentation_video) || urlRegex.test(this.user.presentation_video);
    },
    isTestTherapist() {
      return Boolean(this.therapistConfigs.is_test) || false;
    },
    async transferMembers() {
      this.isTransferringMembers = true;
      const ccId = this.$route.params.id;
      await axios.post(`v1/care-coordinators/${ccId}/transfer/all`)
        .then(() => this.$noty.success('Members transfer requested with success'))
        .catch(e => {
          this.$noty.error('An error occurred when requesting members transfer');
          console.debug(e);
        });
      this.isTransferringMembers = false;
    },
  },
};
</script>
