<template>
  <b-container>

    <b-row>
      <b-col class="d-flex justify-content-end">
        <b-button
          size="sm" variant="secondary"
          @click="resetDropDown"
          v-b-modal.modal-add-credential>
          <span class="mr-2">Add credential group</span>
          <feather type="plus"></feather>
        </b-button>
      </b-col>
    </b-row>

    <b-row class="mt-4">
      <b-col>
        <b-table
          responsive=""
          :fields="tableFields"
          :items="professionalCredentials"
          empty-text="No credential groups added, add one using the button above"
          show-empty>
          <template #cell(actions)="data">
            <div class="d-flex">
              <b-button
                title="Delete this credential"
                size="sm"
                variant="light"
                class="client-entry-action"
                @click="deleteCredential(data)"
                v-b-tooltip.hover>
                <feather class="text-danger" type="trash"></feather>
              </b-button>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>

    <b-modal
      id="modal-add-credential"
      ok-title='Add'
      size="lg"
      title='Add new credential group'
      @ok="addCredential"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close>
      <b-row>
        <b-col cols="6">
          <b-form-group
            label="Credential Group">
            <v-select
              id="credential-group-selector"
              :options="credentialSelectOptions"
              :searchable="true"
              v-model="selectedCredential"/>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>

  </b-container>
</template>

<script>
import VSelect from '@alfsnd/vue-bootstrap-select';

export default {
  name: 'CredentialGroupManagement',
  components: {
    VSelect,
  },
  props: {
    allCredentials: {
      type: Array,
      required: true,
    },
    professionalCredentials: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      tableFields: [
        { key: 'name', label: 'Credential Group' },
        { key: 'actions', label: 'Actions' },
      ],
      selectedCredential: {},
    };
  },
  methods: {
    addCredential() {
      const alreadyHasCredential = this.professionalCredentials
        .some(profCredential => (
          profCredential.id === this.selectedCredential.value
        ));

      if (alreadyHasCredential) return;

      const selectedCredentialGroup = this.allCredentials.find(credential => (
        credential.id === this.selectedCredential.value
      ));

      this.professionalCredentials.push(selectedCredentialGroup);
    },
    async deleteCredential(data) {
      const result = await this.$bvModal.msgBoxConfirm(
        'By proceeding the PT will not be available for onboarding for '
        + 'institutions that require this credential. Are you sure you '
        + 'want to proceed?',
        {
          title: 'Confirm credential group deletion',
          okTitle: 'Delete',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true,
          noCloseOnBackdrop: true,
        },
      );

      if (!result) return;

      const indexToRemove = this.professionalCredentials
        .findIndex(credential => ((credential.id === data.item.id)));

      this.professionalCredentials.splice(indexToRemove, 1);
    },
    resetDropDown() {
      const [ firstCredential ] = this.credentialSelectOptions;
      this.selectedCredential = firstCredential;
    },
  },
  computed: {
    credentialSelectOptions() {
      return this.allCredentials.map(credential => ({
        value: credential.id,
        text: credential.name,
      }));
    },
  },
};
</script>

<style>
#credential-group-selector {
  height: calc(1.5em + 0.75rem + 2px);
}

#credential-group-selector button.v-select-toggle {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  height: 100%;
}
</style>
