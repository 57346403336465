<template>
  <b-card header="Capacity Management">
    <b-form-group label="Capacity Level"
                  label-for="capacityLevel">
      <b-form-select id="capacityLevel" v-model="capacity_level">
        <b-form-select-option v-for="{value, label} in capacityLevelOptions"
                              :key="value"
                              :disabled="!$acl.check('users.edit')"
                              :value="value">{{ label }}
        </b-form-select-option>
      </b-form-select>
    </b-form-group>

    <div class="form-inline">
      <b-form-checkbox
        id="stop_auto_progression_checkbox"
        v-model="stop_auto_progression"
        :disabled="!$acl.check('users.edit')"
        :unchecked-value="false"
        :value="true"
        name="stop_auto_progression_checkbox">
        Stop Auto Capacity Level Rules
      </b-form-checkbox>

      <div class="ml-2">
        <feather id="explanation-stop_auto_progression_checkbox" class="fa text-info" type="info"></feather>
        <b-tooltip placement="bottom" target="explanation-stop_auto_progression_checkbox">
          If you turn ON, the Professional will remain at same Level unless someone changes it manually.
          As soon you turn OFF, the time to progress will start counting.
        </b-tooltip>
      </div>
    </div>

    <div class="form-inline">
      <b-form-checkbox id="stop_enrollments_checkbox" v-model="stop_enrollments" :disabled="!$acl.check('users.edit')"
        :unchecked-value="false" :value="true" name="stop_enrollments_checkbox">
        Stop Enrollments
      </b-form-checkbox>
      <div class="ml-2">
        <feather id="explanation-stop_enrollments_checkbox" class="fa text-info" type="info"></feather>
        <b-tooltip placement="bottom" target="explanation-stop_enrollments_checkbox">
          If you turn ON, the Professional will no longer appear on onboarding.
        </b-tooltip>
      </div>
    </div>
  </b-card>
</template>

<script>
export default {
  name: 'CapacityManagement',
  props: {
    therapistConfigs: Object,
    totalLevels: {
      type: Number,
      default: 4,
    },
  },
  computed: {
    capacityLevelOptions() {
      const levels = Array.from({ length: this.totalLevels + 1 }, (_, i) => ({
        value: i,
        label: `Level ${i}`,
      }));

      return [ { value: null, label: 'None' }, ...levels ];
    },
    capacity_level: {
      get() {
        return this.therapistConfigs.capacity_level;
      },
      set(value) {
        this.therapistConfigs.capacity_level = value;
      },
    },
    stop_auto_progression: {
      get() {
        return Boolean(this.therapistConfigs.stop_auto_progression) || false;
      },
      set(value) {
        this.therapistConfigs.stop_auto_progression = value;
      },
    },
    stop_enrollments: {
      get() {
        return Boolean(this.therapistConfigs.stop_enrollments) || false;
      },
      set(value) {
        this.therapistConfigs.stop_enrollments = value;
      },
    },
  },
};
</script>
